import React from 'react'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'

const createApolloClient = (endpoint: string, accessToken: string) => {
  const authLink = setContext(async (_, { headers }) => {
    headers = { ...headers }
    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`
    }

    return { headers }
  })

  const httpLink = new HttpLink({
    fetch,
    uri: endpoint,
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
  return client
}

export const ApolloAppProvider: React.FunctionComponent = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const [client, setClient] = React.useState<ApolloClient<NormalizedCacheObject>>()
  const [token, setToken] = React.useState<string>()
  React.useEffect(() => {
    const getToken = async () => {
      const t = await getAccessTokenSilently({
        audience: 'https://www.prophecyinvestments.com',
      })
      console.log({ jwt: t })
      setToken(t)
    }
    getToken()
  }, [])
  React.useEffect(() => {
    if (!token) return
    setClient(createApolloClient(process.env.REACT_APP_REST_ENDPOINT as string, token))
  }, [token])

  if (!client) return <div>Loading...</div>
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
