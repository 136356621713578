import React from 'react'
import { Button, Layout, Menu, Space, Typography } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import { PicksLayout } from '../../components/PicksLayout'
import { PicksForm } from '../../components/PicksForm'
import { Pick } from '../../constants/types'
const { Header, Footer, Sider, Content } = Layout
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import moment from 'moment'

export const HomePage: React.FunctionComponent = () => {
  const { user, logout } = useAuth0()
  console.log({ user })
  const [formVisible, setFormVisible] = React.useState(false)
  const [loggingOut, setLogginOut] = React.useState(false)

  const createNewPick = useMutation(async (newPickData: Partial<Pick>) => {
    const res = await fetch(`${process.env.REACT_APP_REST_ENDPOINT}/pick`, {
      method: 'POST',
      body: JSON.stringify({
        ...newPickData,
        startTime: moment(newPickData.startTime).utc().format(),
      } as Partial<Pick>),
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    const response = await res.json()
    console.log({ response })
    return await res.json()
  })
  return (
    <>
      <Layout style={{ minHeight: '100vh', color: 'whitesmoke' }}>
        <Header style={{ color: 'whitesmoke', textAlign: 'end' }}>
          <Button onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
        </Header>
        <Layout>
          <Sider>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['data-entry']}>
              <Menu.Item key="data-entry">Capper data</Menu.Item>
              <Menu.SubMenu title="Subscribers" key="subscribers">
                <Menu.Item>Active</Menu.Item>
                <Menu.Item>Past</Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu title="Cappers" key="cappers">
                <Menu.Item>Analytics</Menu.Item>
                <Menu.Item>Commissions</Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Sider>
          <Content style={{ color: 'black', padding: 24 }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Title>Picks</Typography.Title>
              <PicksForm
                isVisible={formVisible}
                onCancel={() => setFormVisible(false)}
                onSave={(formData) => {
                  setFormVisible(false)
                  console.log({ formData })
                  createNewPick.mutate(formData.betFormData, {
                    onSuccess: (data) => console.log('refresh window', { data }),
                    onSettled: (data) => console.log('settled', { data }),
                  })
                }}
              />
              <Button
                onClick={() => {
                  setFormVisible((visible) => !visible)
                }}
              >
                Add new
              </Button>
              <PicksLayout />
            </Space>
          </Content>
        </Layout>
        <Footer style={{ textAlign: 'center' }}>PROPHECY INVESTMENTS &#169; 2021 </Footer>
      </Layout>
    </>
  )
}
