import React, { useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Pick } from '../../constants/types'
import { PickCard } from '../PickCard'

export const PicksLayout: React.VFC = () => {
  const { data, isLoading, error } = useQuery<Pick[]>('appPicks', async () => {
    const data = await fetch(`${process.env.REACT_APP_REST_ENDPOINT}/pick/all`)
    return await data.json()
  })

  const deleteMutation = useMutation(async (id: string) => {
    const response = await fetch(`${process.env.REACT_APP_REST_ENDPOINT}/pick/${id}`, {
      method: 'DELETE',
    })
    console.log({ response })
  })

  useEffect(() => {
    console.log({ data, isLoading, error })
    if (!data) return
  }, [data, isLoading, error])

  if (isLoading || !data) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>
  console.log({ data })
  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {data?.map((pick) => (
          <PickCard key={pick.id} pick={pick} onDelete={() => deleteMutation.mutate(pick.id)} />
        ))}
      </div>
    </>
  )
}
