import React from 'react'
import { Login } from './components/Login'
import './App.css'
import { HomePage } from './pages'
import { Auth0Provider } from '@auth0/auth0-react'
import { ApolloAppProvider } from './components/ApolloProvider'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient()

function App(): JSX.Element {
  return (
    <Auth0Provider
      domain="dev-f7ebls36.us.auth0.com"
      clientId="xEIGnnvxnvIdbSTiNHFPtIhB5ZShkeG6"
      redirectUri={window.location.origin}
      // audience={'https://www.prophecyinvestments.com'}
      audience={'https://dev-f7ebls36.us.auth0.com/userinfo'}
      // issuer="https://dev-f7ebls36.us.auth0.com/"
    >
      <Login>
        <ApolloAppProvider>
          <QueryClientProvider client={queryClient}>
            <HomePage />
          </QueryClientProvider>
        </ApolloAppProvider>
      </Login>
      <ToastContainer
        containerId="toastId"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
    </Auth0Provider>
  )
}

export default App
