import { Form, Input, Button, Checkbox, Card, Row, Typography } from 'antd'
import { Maybe } from 'graphql/jsutils/Maybe'
import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
export const Login: React.FunctionComponent<{
  // onLoginClick: ({ email, password }: { email: string; password: string }) => void
  // onLoginClick: () => void
  error?: Maybe<string>
}> = ({ error, children }) => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0()
  const onFinish = (values: any) => {
    // const { email, password } = values
    // onLoginClick()
    loginWithRedirect()
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  if (isAuthenticated) return <>{children}</>
  else if (isLoading) return <>Loading...</>
  else loginWithRedirect()
  return <>Loading...</>
  /* return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Card
        style={{
          marginTop: '3rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          // minWidth: 480,
          // maxWidth: 720
          width: 720,
        }}
      >
        <Form
          style={{
            marginTop: '3rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            // maxWidth: 720
          }}
          name="basic"
          // labelCol={{ span: 4 }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            // label="Email"
            name="email"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input your username!',
            //   },
            // ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            // label="Password"
            name="password"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input your password!',
            //   },
            // ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              // offset: 4,
              span: 24,
            }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          {error && <Typography.Text type="danger">{error?.toString()}</Typography.Text>}
          <Form.Item
            wrapperCol={{
              // offset: 4,
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Row>
  ) */
}
