import React from 'react';
import { Modal, Form, Input, InputNumber, Checkbox, DatePicker, Space, Button } from 'antd'
import { disabledDate } from '../../utils'
// import { Bets_Insert_Input } from '../../graphql/hooks'
import { Pick } from '../../constants/types'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'

export const PicksForm: React.VoidFunctionComponent<{
  isVisible: boolean
  onSave: ({ betFormData }: { betFormData: Partial<Pick> }) => void
  onCancel: () => void
}> = (props) => {
  const [form] = Form.useForm<Partial<Pick>>()

  /* const initalValues: Omit<Bets_Insert_Input, 'createdBy' | '_partition'> = {
    bet_units: 0,
    capper_name: '',
    captured_by: '',
    daily_card_date: '',
    game_date: '',
    parties: '',
    selection: '',
    league_name: '',
  } */

  return (
    <Modal
      visible={props.isVisible}
      title="Add a bet"
      onCancel={() => {
        props.onCancel()
      }}
      onOk={() => {
        const formData = form.getFieldsValue()
        props.onSave({ betFormData: formData })
        form.resetFields()
      }}
    >
      <Form
        form={form}
        name="newBet"
        labelAlign="right"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        colon={false}
        requiredMark={false}
      >
        <Form.Item
          label="League"
          name="leagueName"
          rules={[{ required: true, message: 'Name of league is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Pick type"
          name="pickType"
          rules={[{ required: true, message: 'Pick type is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Game start time"
          name="startTime"
          rules={[{ required: true, message: 'Game date is required' }]}
        >
          <DatePicker
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            format="YYYY-MM-DD HH:mm:ss"
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item label="Tag " name="tag" valuePropName="checked">
          <InputNumber />
        </Form.Item>

        <Form.List name="lines">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'team']}
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <Input placeholder="Team Name" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'tag']}
                    rules={[{ required: true, message: 'Missing last name' }]}
                  >
                    <InputNumber placeholder="Team's Tag" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'highlight']}
                    valuePropName="checked"
                    label="Winning?"
                    rules={[{ required: true, message: 'Missing last name' }]}
                  >
                    <Checkbox />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add team
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item></Form.Item>

        {/* <Form.Item
          label="Capper"
          name="capper_name"
          rules={[{ required: true, message: "Capper's name is required" }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item label="Mark as active " name="active" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item label="Publish to app " name="published" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item label="Future pick " name="upcoming" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(previous, current) =>
            current.isInDailyCard && previous.gameDate !== current.gameDate
          }
          dependencies={['gameDate', 'isInDailyCard']}
        >
          {({ getFieldValue }) => {
            return getFieldValue('isInDailyCard') ? (
              <Form.Item
                name="daily_card_date"
                label="Daily card date"
                initialValue={getFieldValue('gameDate')}
              >
                <DatePicker
                  picker="date"
                  disabledDate={disabledDate}
                  // defaultValue={getFieldValue('gameDate')}
                />
              </Form.Item>
            ) : null
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
}
