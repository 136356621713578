import React from 'react'
import { Card, Typography, Checkbox } from 'antd'
import { Pick } from '../../constants/types'
import { DeleteOutlined } from '@ant-design/icons'
const { Text } = Typography
export const PickCard: React.VFC<{ pick: Pick; onDelete: () => void }> = ({ pick, onDelete }) => {
  return (
    <Card
      title={`${pick.leagueName} (${pick.pickType})  [${pick.tag}]`}
      style={{
        width: '400px',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem',
        borderRadius: '10px',
      }}
    >
      <DeleteOutlined onClick={onDelete} />
      {pick.lines.map((line) => (
        <Card
          key={line?.team}
          title={line?.team}
          style={{
            borderRadius: '5px',
            marginBottom: '3px',
            backgroundColor: line?.highlight ? '#b8ac79' : 'whitesmoke',
          }}
        >
          {line?.tag}
        </Card>
      ))}
      <Text>
        Is active: <Checkbox disabled checked={Boolean(pick?.active)} />
      </Text>
      <br />
      <Text>
        Is published: <Checkbox disabled checked={Boolean(pick?.published)} />
      </Text>
      <br />
      <Text>Published at: {pick.pickPublishDate}</Text>
      <br />
      <Text>Start time: {pick.startTime}</Text>
      <br />
    </Card>
  )
}
